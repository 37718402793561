.image-gallery {
    width: 100%;
    height: auto;
}

.image-gallery-slide img {
    width: 100%;
   
    max-height: 80vh;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
}
.element-center{
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
    flex-wrap: nowrap;
}

.fullscreen .image-gallery-slide img {
    height: 100%;
    width: 100%;
}
.cardtext {
    
    width: 100%;
    height: 100%;
    
    background: rgb(255, 255, 255);
    
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 255, .2);
    transition: all .2s;
    box-shadow: 12px 12px 2px 1px rgba(0, 0, 255, .2);
   
   
}
@media  only screen and  (max-width: 600px) {
    .cardtext{max-width:350px;} 
}
.cardnew2 {
    
    width: 100%;
    height: 95%;
    background: rgb(255, 255, 255);
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 255, .2);
    transition: all .2s;
    box-shadow: 12px 12px 2px 1px rgba(0, 0, 255, .2);
}

.cardnewresim {
    
    
    height: 100%;
    background: rgb(255, 255, 255);
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 255, .2);
    transition: all .2s;
    box-shadow: 12px 12px 2px 1px rgba(0, 0, 255, .2);
}
.cardnewGalery {
    position: relative;
    max-width: 350px;
    background: rgb(255, 255, 255);
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 255, .2);
    transition: all .2s;
    box-shadow: 12px 12px 2px 1px rgba(0, 0, 255, .2);
}
.cardnewharita {
    
    max-width: 350px;
    background: rgb(255, 255, 255);
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 255, .2);
    transition: all .2s;
    box-shadow: 12px 12px 2px 1px rgba(0, 0, 255, .2);
}
.scrollBarNew {
    scrollbar-width: initial;
  
    height: 150px;
    width: 3rem;
    overflow-y: scroll;
   

}





#style-2::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
}

#style-2::-webkit-scrollbar
{
	width: 15px;
	background-color: #F5F5F5;
}

#style-2::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #ad4bd5!important;
}


.react-swipe-container{
    top:5%;
}